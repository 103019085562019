import axios from 'axios'
const URL = process.env.REACT_APP_SERVER_URL;

const ConvoisService = {
    fetchConvois : async(colonyId) => {
    try {
        const response = await axios.get(
          `${URL}/api/convois/${colonyId}`,
        );
        return response.data;
      } catch (err) {
        console.log(err, 'fetchConvois');
      }
    }
}

export default ConvoisService;