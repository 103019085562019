import React, { useEffect } from 'react';
import './App.css';
import './App_global.css';
import './assets_styles.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Home from './pages/Home';
import Construction from './pages/Construction';
import NavBar from './components/NavBar';
import { login, logout } from './features/authSlice';
import Laboratoire from './pages/Laboratoire';
import { Ressources } from './pages/Ressources';
import Army from './pages/Army';
import Map from './pages/Map';
import Enemy from './pages/Enemy';
import AcquerirTerrain from './pages/AcquerirTerrain';
import ColonyAndArmies from './pages/ColonyAndArmies';
import Tuto from './pages/Tuto';
import Guilds from './pages/Guild';
import Acceuil from './pages/Acceuil';
import Convois from './pages/Convois';
import ChallengeTuto from './components/ChallengeTuto';
import CommingSoon from './pages/CommingSoon';
import MessagerieTDC from './pages/messagerie/MessagerieTDC';
import MessagerieAttack from './pages/messagerie/MessagerieAttack';
import MessagerieDefence from './pages/messagerie/MessagerieDefence';
import Forest_Tropical from './Forest_Tropical.mp3'

const App = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isAuthenticated') === 'true';
    if (isLoggedIn) {
      dispatch(login());
    } else {
      dispatch(logout());
    }
  }, [dispatch]);

  return (
    <Router >    
      {isAuthenticated ? (
        <div className='container_nav'>
          <NavBar />
        </div>
      ) : null}
      <Routes className='mr20'>
        <Route path='/acceuil' element={<Acceuil />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        {isAuthenticated ? (
          <>
            <Route path='/' element={<Home />} />
            <Route path='/construction' element={<Construction />} />
            <Route path='/laboratoire' element={<Laboratoire />} />
            <Route path='/colonies' element={<ColonyAndArmies />} />
            <Route path='/army' element={<Army />} />
            <Route path='/map' element={<Map />} />
            <Route path='/ennemies' element={<Enemy />} />
            <Route path='/ressources' element={<Ressources />} />
            <Route path='/acquerirTerrain' element={<AcquerirTerrain />} />
            <Route path='/convois' element={<Convois />} />
            <Route path='/message_tdc' element={<MessagerieTDC />} />
            <Route path='/message_attack' element={<MessagerieAttack />} />
            <Route path='/message_defence' element={<MessagerieDefence />} />
            <Route path='/tuto' element={<Tuto />} />
            <Route path='/guilde' element={<Guilds />} />
            <Route path='/challengeTuto' element={<ChallengeTuto />} />
            <Route path='/commingSoon' element={<CommingSoon />} />
          </>
        ) : (
          <Route path='*' element={<Navigate to='/login' />} />
        )}
      </Routes>
    </Router>
  );
};

export default App;
