import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const URL = process.env.REACT_APP_SERVER_URL;

export const fetchEggProductions = createAsyncThunk(
  'eggProductions/fetchEggProductions',
  async (colonyId, thunkAPI) => {
    const state = thunkAPI.getState();
    const response = await axios.get(
      `${URL}/api/colonies/${colonyId}/productions`,
      {
        headers: {
          Authorization: `Bearer ${state.auth.accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const eggProductionSlice = createSlice({
  name: 'eggProductions',
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEggProductions.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default eggProductionSlice.reducer;
