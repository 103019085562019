import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import antsMessage from '../../assets/angry_messagerie.png'
import antsBattle from '../../assets/angry-speakers.png'



const URL = process.env.REACT_APP_SERVER_URL;

const Messagerie = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (    
        <div className='messagerie-title messagerie-miniNav'>
         <img src={antsMessage} className='logo_nav' alt='Logo' />
        <button  className='button_1 fs20' onClick={(e) => navigate('/message_tdc')}>{t('message_tdc')} </button>
        <button className='button_1 fs20' onClick={(e) => navigate('/message_attack')}>{t('message_attack')} </button>
        <button className='button_1 fs20' onClick={(e) => navigate('/message_defence')}>{t('message_defence')} </button>
          {/* <div className=' animation-messagerie-fond'>
          <div className=' animation-messagerie-lazer'>
            <div className='animation-messagerie-lumiere'></div>
            <div className='animation-messagerie-lumiere mt20 '></div>       //////animation nouvel insect decouvert
            <div className='animation-messagerie-lumiere mt32'></div>

            <img src={antsBattle} className='logo_nav animation-messagerie-img ' alt='Logo' />
          </div>
          </div> */}
        </div>
        

  )
};

export default Messagerie;
