// src/components/ResourceModal.js
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';


const ResourceInfo = ({ show, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose} className='modale'>
      <Modal.Header closeButton>
        <Modal.Title className='info-title'>{t('Info')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>{t('ressourcesInfo')} </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResourceInfo;
