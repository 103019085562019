import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const URL = process.env.REACT_APP_SERVER_URL;

const Enemy = () => {
  const { t } = useTranslation();

  const [colonies, setColonies] = useState([]);
  const [battleReports, setBattleReports] = useState([]);
  const [positions, setPositions] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState({});
  const [selectedUnits, setSelectedUnits] = useState({});
  const [pendingAttacks, setPendingAttacks] = useState([]);
  const [showUnitSelector, setShowUnitSelector] = useState(false);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [currentTarget, setCurrentTarget] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [coloniesPerPage] = useState(100);

  const colonyId = useSelector((state) => state.auth.colony.id);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const fetchPositions = useCallback(async () => {
    try {
      const response = await axios.get(`${URL}/api/positions`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setPositions(response.data);
    } catch (error) {
      console.error('Error fetching positions:', error);
    }
  }, [accessToken]);

  useEffect(() => {
    fetchPositions();
    const intervalId = setInterval(() => {
      fetchPositions();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [fetchPositions]);

  const fetchColonies = useCallback(async () => {
    try {
      const response = await axios.get(`${URL}/api/all/getAllColonies`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          page: currentPage,
          limit: coloniesPerPage,
        },
      });

      if (response.data && response.data.data.length > 0) {
        setColonies(response.data.data);
        setTotalPages(response.data.totalPages);
      } else {
        setColonies([]);
        setTotalPages(1);
      }

    } catch (error) {
      console.error('Error fetching colonies:', error);
      setColonies([]);
      setTotalPages(1);
    }
  }, [accessToken, currentPage, coloniesPerPage]);

  useEffect(() => {
    fetchColonies();
  }, [fetchColonies, currentPage]);

  const fetchPendingAttacks = useCallback(async () => {
    try {
      const response = await axios.get(`${URL}/api/pending`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setPendingAttacks(response.data);
    } catch (error) {
      console.error('Error fetching pending attacks:', error);
    }
  }, [accessToken]);

  useEffect(() => {
    fetchPendingAttacks();
    const intervalId = setInterval(() => {
      fetchPendingAttacks();
    }, 2000);
    return () => clearInterval(intervalId);
  }, [fetchPendingAttacks]);

  const fetchAvailableUnits = useCallback(async () => {
    try {
      const response = await axios.get(`${URL}/api/available-units`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setAvailableUnits(response.data);
    } catch (error) {
      console.error('Error fetching available units:', error);
    }
  }, [accessToken]);

  useEffect(() => {
    if (showUnitSelector) {
      fetchAvailableUnits();
    }
  }, [showUnitSelector, fetchAvailableUnits]);

  const handleAttack = async () => {
    try {
      const response = await axios.post(
        `${URL}/api/attack`,
        {
          attacker_colony_id: colonyId,
          defender_colony_id: currentTarget.defenderColonyId,
          position_id: currentTarget.positionId,
          units: selectedUnits,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setBattleReports(response.data);
      fetchPendingAttacks();
      closeUnitSelector();
    } catch (error) {
      console.error('Error attacking:', error);
    }
  };

  const formatTime = (date) => {
    const now = new Date();
    const timeDiff = new Date(date) - now;
    if (timeDiff <= 0) {
      return '00:00:00';
    }
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const openUnitSelector = (defenderColonyId, positionId) => {
    setCurrentTarget({ defenderColonyId, positionId });
    setShowUnitSelector(true);
  };

  const closeUnitSelector = () => {
    setShowUnitSelector(false);
    setSelectedUnits({});
  };

  const handleUnitChange = (unitId, count) => {
    setSelectedUnits((prev) => ({
      ...prev,
      [unitId]: count,
    }));
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <h2>{t('colonies_title')}</h2>
      <ul>
        {colonies.map((colony) => (
          <li key={colony.id}>
            {colony.name} ({t('terrain_of_hunt')}: {colony.terrain_chasse})
            {Math.abs(
              colony.terrain_chasse -
              (colonies.find((c) => c.id === colonyId)?.terrain_chasse || 0)
            ) <=
              (colonies.find((c) => c.id === colonyId)?.terrain_chasse || 0) *
              0.2 && (
                <>
                  <select
                    value={selectedDestination[colony.id] || ''}
                    onChange={(e) =>
                      setSelectedDestination({
                        ...selectedDestination,
                        [colony.id]: e.target.value,
                      })
                    }
                  >
                    <option value='' disabled>
                      {t('select_position')}
                    </option>
                    {positions.map((position) => (
                      <option key={position.id} value={position.id}>
                        {position.name}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={() =>
                      openUnitSelector(colony.id, selectedDestination[colony.id])
                    }
                    disabled={!selectedDestination[colony.id]}
                  >
                    {t('attack')}
                  </button>
                </>
              )}
          </li>
        ))}
      </ul>
      <Pagination
        coloniesPerPage={coloniesPerPage}
        totalColonies={totalPages * coloniesPerPage}
        paginate={paginate}
        currentPage={currentPage}
      />
      {showUnitSelector && (
        <div className='unit-selector-modal'>
          <h3>{t('select_units')}</h3>
          <ul>
            {availableUnits.map((unit) => (
              <li key={unit.unit_id}>
                {unit.name}: {unit.count}
                <input
                  type='number'
                  min='0'
                  max={unit.count}
                  value={selectedUnits[unit.unit_id] || 0}
                  onChange={(e) =>
                    handleUnitChange(unit.unit_id, parseInt(e.target.value))
                  }
                />
              </li>
            ))}
          </ul>
          <button onClick={handleAttack}>{t('send_attack')}</button>
          <button onClick={closeUnitSelector}>{t('cancel')}</button>
        </div>
      )}
      {battleReports.length > 0 && (
        <div className='battle-result-popup'>
          <h3>{t('battle_reports')}</h3>
          {battleReports.map((report) => (
            <div key={report.position_id}>
              <h4>{t('result')}: Position {report.position_id}</h4>
              <p>{t('attacker_losses')}:
                {report.attacker_losses.length > 0
                  ? report.attacker_losses.map((loss) => (
                    <span key={loss.unit_id}>
                      Unité {loss.unit_id}: {loss.count}
                    </span>
                  ))
                  : t('none')}
              </p>
              <p>{t('defender_losses')}:
                {report.defender_losses.length > 0
                  ? report.defender_losses.map((loss) => (
                    <span key={loss.unit_id}>
                      Unité {loss.unit_id}: {loss.count}
                    </span>
                  ))
                  : t('none')}
              </p>
            </div>
          ))}
          <button onClick={() => setBattleReports([])}>{t('close')}</button>
        </div>
      )}
      {pendingAttacks.length > 0 && (
        <div
          className='pending-attacks-section'
          style={{
            marginTop: '20px',
            padding: '10px',
            border: '1px solid black',
          }}
        >
          <h3>{t('pending_attacks')}</h3>
          <ul>
            {pendingAttacks.map((attack) => (
              <li key={attack.id}>
                {t('attack_on')} {attack.defenderColony.name} at {attack.Position.name}{' '}
                - {t('time_remaining')}: {formatTime(attack.scheduled_time)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const Pagination = ({ coloniesPerPage, totalColonies, paginate, currentPage }) => {
  const pageNumbers = [];

  if (totalColonies > 0) {
    for (let i = 1; i <= Math.ceil(totalColonies / coloniesPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <button
              onClick={() => paginate(number)}
              className="page-link"
              style={{ cursor: 'pointer', border: 'none' }}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};


export default Enemy;
