import React, { useEffect, useRef, useState } from 'react';
import spriteBlackAnts from '../../../assets/spriteAntsBlack.png';

const BlackAntsAnimation = (animation ) => {
  const src = spriteBlackAnts;
  const cropWidth = 130;
  const cropHeight = 291;
  const position = [60, 265, 468, 670];
  const [cropPosition, setCropPosition] = useState(0);
  const canvasRef = useRef(null);
  let i = 0;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (i <= 3) {
        setCropPosition(position[i]);
        i++;
      } else {
        i = 0;
        setCropPosition(position[i]);
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, [position]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if(canvas !== null){
        
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = src;

        img.onload = () => {
        ctx.clearRect(0, 0, cropWidth, cropHeight);
        ctx.drawImage(img, cropPosition, 0, cropWidth, cropHeight, 0, 0, 40, 65);
        };
    }
  }, [cropPosition, src, cropWidth, cropHeight]);

  if (animation && animation === 1) {
    return (
      <div className='animationLeft'>
        <canvas ref={canvasRef} width={40} height={65} />
      </div>
    );
  } else if (animation && animation === 2) {
    return (
      <div className='animationRight'>
        <canvas ref={canvasRef} width={40} height={65} />
      </div>
    );
  }
    return
};

export default BlackAntsAnimation;
