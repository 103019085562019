import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import angryAnts from '../../assets/angry-speakers.png'

const ErrorModal = ({ show, handleClose, errorMessage }) => {
  return (
    <Modal show={show} onHide={handleClose} className='modale2'>
      <Modal.Header closeButton>
        <Modal.Title>Erreur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='challenge-ended-info'>
          {errorMessage}
          <img src={angryAnts} className='logo_nav' alt='Logo' />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
