import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, register } from '../../features/authSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo_1.png';

const Register = () => {
  const { t } = useTranslation();

  const registerError = useSelector((state) => state.auth.error);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRegister = async() => {
    await dispatch(register({ username, navigate, password }))
      .unwrap()
      .then(async() => {
        await dispatch(login({ username, password }));
        localStorage.setItem('isAuthenticated', 'true');
        navigate('/');
      })
      .catch((error) => {
        console.error('Registration failed:', error);
      });
  };

  return (
    <div className='auth-container'>
            <p className='game_version'>BETA</p>
      <img src={logo} className='logo_auth' alt='Logo' />
      <h2 className='auth-title'>{t('register')}</h2>
      <div>
        <input
          type='text'
          placeholder={t('username')}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className='auth-input'
        />
      </div>
      <div>
        <input
          type='password'
          placeholder={t('password')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className='auth-input'
        />
      </div>
      <p>{registerError} </p>
      <div className='buttons-login-register'>
        <button onClick={handleRegister} className='mr10 fs25 button_1'>
          {t('play')}
        </button> 
        <button onClick={() => navigate('/login')} className=' fs25 button_1'>
          {t('login')}
        </button>
      </div>
    </div>
  );
};

export default Register;
