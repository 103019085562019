import React from 'react';

const Button = ({ width, height, color, className, onClick, disabled, style, children }) => {
    const buttonStyle = {
        width: width || 'auto',
        height: height || '48px',
        // backgroundColor: color || 'transparent',
        // backgroundImage: !color && 'radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%)',
        color: '#fff',
        ...style,
    };

    return (
        <button
            className={`button_1 ${className}`}
            style={buttonStyle}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
