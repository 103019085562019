import React, { useEffect } from 'react';

const Acceuil = () => {
    useEffect(() => {
        const { gsap, ScrollTrigger } = window;

        gsap.registerPlugin(ScrollTrigger);

        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: '.wrapper',
                start: 'top top',
                end: '+=150%',
                pin: true,
                scrub: true,
            }
        });

        timeline
            .to('img', {
                scale: 2,
                z: 350,
                transformOrigin: 'center center',
                ease: 'power1.inOut'
            })
            .to('.section.hero', {
                scale: 1.5,
                transformOrigin: 'center center',
                ease: 'power1.inOut'
            });

        return () => {
            // Cleanup animation and ScrollTrigger instances
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            timeline.kill();
        };
    }, []);

    return (
        <div className="wrapper">
            <div className="content">
                <section className="section hero"></section>
            </div>
            <div className="image-container">
                <img src="https://codetheworld.io/wp-content/uploads/2024/05/main-scaled.webp" alt="Main" />
            </div>
        </div>
    );
}

export default Acceuil;
