import React, { useEffect, useState } from 'react';
import { fetchChallengeTuto } from '../features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { FaAppleAlt } from 'react-icons/fa';
import ChallengeEndedInfo from './InformationModal.js/ChallengeEndedInfo';

const URL = process.env.REACT_APP_SERVER_URL;

const ChallengeTuto = ({ show, handleClose }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const colony = useSelector((state) => state.auth.colony);
  const challengeTuto = useSelector((state) => state.auth.challengeTuto);

  const [rewardTuto, setRewardTuto] = useState({});
  const [six, setSix] = useState(null);
  const [challengeEndedShow, setChallengeEndedShow] = useState(false)

  const handleChallengeEndedClose = () => setChallengeEndedShow(false)
  const handleChallengeEndedShow = () => setChallengeEndedShow(true)

  const fetchRewardTuto = async () => {
    try {
      const response = await axios.get(`${URL}/api/rewardTuto/${colony.id}`);
      setRewardTuto(response.data);
    } 
    catch (err) {
    }
  };

  const updateRewardTuto = async (rewardNumber) => {
    try {
      const response = await axios.post(`${URL}/api/rewardTuto/update`, {
        colony_id: colony.id,
        rewardNumber: rewardNumber,
      });
      setRewardTuto(response.data);
    } catch (err) {
    }
  };

  useEffect(() => {
    dispatch(fetchChallengeTuto());
    fetchRewardTuto();
    if (rewardTuto.one > 0) {
      if (rewardTuto.two > 0) {
        if (rewardTuto.three > 0) {
          if (rewardTuto.four > 0) {
            if (rewardTuto.five > 0) {
              setSix(true);
            }
          }
        }
      }
    }
    const interval = setInterval(async () => {
      if (rewardTuto.one > 0) {
        if (rewardTuto.two > 0) {
          if (rewardTuto.three > 0) {
            if (rewardTuto.four > 0) {
              if (rewardTuto.five > 0) {
                setSix(true);
              }
            }
          }
        }
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [dispatch, colony]);

  const getButtonColor = (rewardNumber) => {
    switch (rewardNumber) {
      case 1:
        if (rewardTuto.one === 0) {
          return '#da3630';
        } else if (rewardTuto.one === 1) {
          return '#30da5a';
        } else if (rewardTuto.one === 2) {
          return '#414141';
        }
        break;
      case 2:
        if (rewardTuto.two === 0) {
          return '#da3630';
        } else if (rewardTuto.two === 1) {
          return '#30da5a';
        } else if (rewardTuto.two === 2) {
          return '#414141';
        }
        break;
      case 3:
        if (rewardTuto.three === 0) {
          return '#da3630';
        } else if (rewardTuto.three === 1) {
          return '#30da5a';
        } else if (rewardTuto.three === 2) {
          return '#414141';
        }
        break;
      case 4:
        if (rewardTuto.four === 0) {
          return '#da3630';
        } else if (rewardTuto.four === 1) {
          return '#30da5a';
        } else if (rewardTuto.four === 2) {
          return '#414141';
        }
        break;
      case 5:
        if (rewardTuto.five === 0) {
          return '#da3630';
        } else if (rewardTuto.five === 1) {
          return '#30da5a';
        } else if (rewardTuto.five === 2) {
          return '#414141';
        }
        break;
      case 6:
        if (rewardTuto.six === 0) {
          return '#da3630';
        } else if (rewardTuto.six === 1) {
          return '#30da5a';
        } else if (rewardTuto.six === 2) {
          return '#414141';
        }
        break;
      default:
        return '#414141';
    }
  };

  const hasRewardTutoKeys = Object.keys(rewardTuto).length > 0;

  return (
    <Modal show={show} onHide={handleClose} className='modale'>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <div className='challenge'>
          {hasRewardTutoKeys && challengeTuto && challengeTuto.length > 0 ? (
            <div>
              {rewardTuto &&
                challengeTuto.map((challenge, index) =>
                  index !== 5 || six === true ? (
                    <div key={index} className='challenge-handler'>
                      <p>
                        {challenge.id} {challenge.challenge}
                      </p>
                      <button
                        className='button_challenge'
                        onClick={() => {
                          if (index === 0 && rewardTuto.one === 1) {
                            updateRewardTuto(1);
                          } else if (index === 1 && rewardTuto.two === 1) {
                            updateRewardTuto(2);
                          } else if (index === 2 && rewardTuto.three === 1) {
                            updateRewardTuto(3);
                          } else if (index === 3 && rewardTuto.four === 1) {
                            updateRewardTuto(4);
                          } else if (index === 4 && rewardTuto.five === 1) {
                            updateRewardTuto(5);
                          } else if (index === 5 && rewardTuto.six === 1) {
                            updateRewardTuto(6);   
                            handleChallengeEndedShow()               
                          }
                        }}
                        style={{ backgroundColor: getButtonColor(index + 1) }}
                      >
                        {challenge.reward}
                        <FaAppleAlt className='icon-nav icon-nav-food-wood icon_color_apple_tuto' />
                      </button>
                    </div>
                  ) : null
                )}
            </div>
          ) : null}
           <ChallengeEndedInfo
          show={challengeEndedShow}
          handleClose={handleChallengeEndedClose}
          colony={colony}
         />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChallengeTuto;
