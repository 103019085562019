import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchColony, logout } from '../features/authSlice';
import ResourceModal from './ResourceModal';
import { FaAppleAlt } from 'react-icons/fa';
import logo from '../assets/logo_1.png';
import sprite from '../assets/angry-guide.png';
import LanguageSwitcher from './LanguageSwitcher';
import { GiWoodStick } from 'react-icons/gi';
import { IoIosWater } from 'react-icons/io';
import { GiDrippingHoney } from 'react-icons/gi';
import { BsChevronDown } from 'react-icons/bs';
import ChallengeTuto from './ChallengeTuto';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import IconFood from '../assets/IconFood.png';
import IconWater from '../assets/IconWater.png';
import IconHoney from '../assets/IconHoney.png';
import IconWood from '../assets/IconWood.png';

import Forest_Tropical from '../assets/sounds/Forest_Tropical.mp3';

const URL = process.env.REACT_APP_SERVER_URL;

const NavBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const colony = useSelector((state) => state.auth.colony);

  const [showResourceModal, setShowResourceModal] = useState(false);
  const [showTutoModal, setShowTutoModal] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [rewardTuto, setRewardTuto] = useState({});

  const fetchRewardTuto = async () => {
    try {
      const response = await axios.get(`${URL}/api/rewardTuto/${colony.id}`);
      setRewardTuto(response.data);
    } catch (err) { }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleResourceModalClose = () => setShowResourceModal(false);
  const handleResourceModalShow = () => setShowResourceModal(true);
  const handleTutoModalClose = () => setShowTutoModal(false);
  const handleTutoModalShow = () => setShowTutoModal(true);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const closeMenu = () => {
    setExpanded(false);
  };

  useEffect(() => {
    var audio = document.getElementById('myaudio');
    if (audio) {
      audio.volume = 0.3;
    }
    dispatch(fetchColony);
    fetchRewardTuto();
    hasRewardTutoKeys = Object.keys(rewardTuto).length > 0;
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [dispatch]);

  let hasRewardTutoKeys = Object.keys(rewardTuto).length > 0;

  return (
    <Navbar
      expand='xxl'
      className={`navbar fixed-top ${scrolled ? 'scrolled' : ''}`}
      expanded={expanded}
    >
      <div
        onClick={() => {
          handleResourceModalShow();
        }}
        className={`nav-link-ressourceModal ${scrolled ? 'scrolled' : ''}`}
      >
      <div className="nav-ressourceModal">
  <div className="resource-column">
    <div className="resource-item">
      <img className="resource-icon" src={IconFood} alt="Icon food" />
      <p className="resource-value">{colony ? colony.food_storage : null}</p>
    </div>
    <div className="resource-item">
      <img className="resource-icon" src={IconWood} alt="Icon wood" />
      <p className="resource-value">{colony ? colony.wood_storage : null}</p>
    </div>
  </div>
  <div className="resource-column">
    <div className="resource-item">
      <img className="resource-icon" src={IconWater} alt="Icon water" />
      <p className="resource-value">{colony ? colony.water_storage : null}</p>
    </div>
    <div className="resource-item">
      <img className="resource-icon" src={IconHoney} alt="Icon honey" />
      <p className="resource-value">{colony ? colony.honey_storage : null}</p>
    </div>
  </div>
  <BsChevronDown className="dropdown-icon" />
</div>

      </div>
      <Container>
        <Navbar.Brand href='/'>
          <img src={logo} className='logo_nav' alt='Logo' />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          onClick={() => {
            handleToggle();
          }}
        />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <NavDropdown
              title={t('Fourmilière')}
              id='basic-nav-dropdown'
              className={` ${scrolled ? 'scrolled' : ''}`}
            >
              <div className='dropdown-columns'>
                <NavDropdown.Item href='/' onClick={closeMenu}>
                  {t('Reine')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/construction' onClick={closeMenu}>
                  {t('Construction')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/laboratoire' onClick={closeMenu}>
                  {t('Laboratoire')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/ressources' onClick={closeMenu}>
                  {t('Ressources')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/army' onClick={closeMenu}>
                  {t('Armée')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/ennemies' onClick={closeMenu}>
                  {t('Ennemies')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/colonies' onClick={closeMenu}>
                  {t('Colonies')}
                </NavDropdown.Item>

                <NavDropdown.Item href='/map' onClick={closeMenu}>
                  {t('Carte')}
                </NavDropdown.Item>
                <NavDropdown.Item href='/convois' onClick={closeMenu}>
                  {t('Convoie')}
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title={t('Alliance')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled' : ''}
            >
              <div className='dropdown-columns'>
                <NavDropdown.Item href='/guilde' onClick={closeMenu}>
                  {t('Créer / Rejoindre une Guilde')}
                </NavDropdown.Item>

                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Chat
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Forum
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Membres
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Candidatures
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Diplomatie
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Description
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Options
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title={t('Introduction')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled ' : ''}
            >
              <div className='dropdown-columns'>
                <NavDropdown.Item href='/tuto' onClick={closeMenu}>
                  {t('Introduction')}
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown
              title={t('Communauté')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled' : ''}
            >
              <div className='dropdown-columns'>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Chat
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Echange
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Proposition
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Classement Joueurs
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Classement Alliances
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Mon Profil
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Mon compte
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Parrainage
                </NavDropdown.Item>
                <NavDropdown.Item href='/commingSoon' onClick={closeMenu}>
                  Forum
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <Nav.Link
              href='/message_attack'
              className={`nav-link ${scrolled ? 'scrolled' : ''}`}
              onClick={closeMenu}
            >
              {t('Messagerie')}
            </Nav.Link>
            <NavDropdown
              title={t('Aide')}
              id='basic-nav-dropdown'
              className={scrolled ? 'scrolled' : ''}
            >
              {/* Définir le contenu */}
            </NavDropdown>
          </Nav>
          {/* <audio id='myaudio' controls autoPlay>
        <source src={Forest_Tropical} type='audio/mpeg'></source>     
      </audio> */}
          <Nav.Link
            className={`bouton_log ${scrolled ? 'scrolled' : ''}`}
            onClick={() => {
              handleLogout();
              closeMenu();
            }}
          >
            {t('Déconnecter')}
          </Nav.Link>
          <LanguageSwitcher />
        </Navbar.Collapse>
        <ResourceModal
          show={showResourceModal}
          handleClose={handleResourceModalClose}
          colony={colony}
        />
        {hasRewardTutoKeys ? (
          <Nav.Link
            onClick={handleTutoModalShow}
            className={`nav-link-challengeTuto ${scrolled ? 'scrolled' : ''}`}
          >
            <div className='nav-ressourceModal'>
              <p>{t('ChallengeTuto')}</p>
              <img src={sprite} className='logo_nav' alt='Logo' />
            </div>
          </Nav.Link>
        ) : null}
        <ChallengeTuto
          show={showTutoModal}
          handleClose={handleTutoModalClose}
          colony={colony}
        />
      </Container>
    </Navbar>
  );
};

export default NavBar;
