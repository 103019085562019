import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import logo from '../assets/provisoire_image.jpg';
import testbackground from '../assets/test1.jpg';

import { useTranslation } from 'react-i18next';
import ErrorModal from '../components/assets/ErrorModal';
import { GiHoneyJar, GiWaterDrop, GiWoodBeam, GiAnt } from 'react-icons/gi';
import { FaAppleAlt, FaHourglassHalf } from 'react-icons/fa';

const URL = process.env.REACT_APP_SERVER_URL;

const Laboratoire = () => {
  const { t } = useTranslation();

  const [research, setResearch] = useState([]);
  const colonyId = useSelector((state) => state.auth.colony.id);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const handleErrorClose = () => setShowError(false);
  const handleErrorShow = (message) => {
    setErrorMessage(message);
    setShowError(true);
  };

  const fetchColonyResearch = async () => {
    try {
      const response = await axios.get(
        `${URL}/api/colonies/${colonyId}/research`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log('Fetched colony research:', response.data);
      setResearch(response.data);
    } catch (error) {
      console.error('Error fetching colony research:', error);
    }
  };

  useEffect(() => {
    fetchColonyResearch();

    const interval = setInterval(() => {
      fetchColonyResearch();
    }, 10000);

    return () => clearInterval(interval);
  }, [colonyId, accessToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      setResearch((research) =>
        research.map((item) => {
          if (item.buildEndTime) {
            const now = moment();
            const end = moment(item.buildEndTime);
            const duration = moment.duration(end.diff(now));
            if (duration.asSeconds() <= 0) {
              return { ...item, buildEndTime: null };
            }
          }
          return item;
        })
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleUpgrade = async (researchId) => {
    if (!researchId) {
      console.error('Research ID is undefined');
      return;
    }
    console.log('Trying to upgrade research with ID:', researchId);

    try {
      const response = await axios.post(
        `${URL}/api/research/upgrade`,
        {
          colony_id: colonyId,
          research_id: researchId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log('Upgrade successful:', response.data);
      fetchColonyResearch();
    } catch (error) {
      console.error('Error during upgrade request:', error);
      handleErrorShow('Veuillez consulter vos ressources ou veuillez vous déconnecter/reconnecter.');
    }
  };

  const handleCancelUpgrade = async (researchId) => {
    try {
      console.log('Sending cancel upgrade request with:', { colony_id: colonyId, research_id: researchId });
      const response = await axios.post(
        `${URL}/api/research/cancel`,
        {
          colony_id: colonyId,
          research_id: researchId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
        }
      );
      console.log('Cancel upgrade response:', response.data);
      fetchColonyResearch();
    } catch (error) {
      console.error('Error during cancel upgrade request:', error);
      handleErrorShow('Erreur lors de l\'annulation de la mise à niveau de la recherche.');
    }
  };

  const formatDuration = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    return `${duration.days()}J ${duration.hours()}H ${duration.minutes()}m ${duration.seconds()}s`;
  };

  const getRemainingTime = (endTime) => {
    const now = moment();
    const end = moment(endTime);
    const duration = moment.duration(end.diff(now));
    return `${duration.days()}J ${duration.hours()}H ${duration.minutes()}m ${duration.seconds()}s`;
  };

  const hasOngoingResearch = research.some(
    (item) => item.buildEndTime && moment(item.buildEndTime).isAfter(moment())
  );

  const ongoingResearch = research.find(
    (item) => item.buildEndTime && moment(item.buildEndTime).isAfter(moment())
  );

  return (
    <div>
      {ongoingResearch && (
        <p>
          {t('remaining_time')}: {getRemainingTime(ongoingResearch.buildEndTime)}
        </p>
      )}
      <ul className='ul_construction police_Electrolize'>
        {research.map((item) => (
          <li className='list_construction mb20' key={item.id}>
            <div>
              <div className='flex background_info_build_resh'>
                <img
                  className='image_laboratoire'
                  src={item.imageUrl ? `${URL}${item.imageUrl}` : logo}
                  alt='research'
                />
                <div className='info_construction flex'>
                  <div className='box_niv_name'>
                    <h3>{item.name}</h3>
                    <p className='h3_big_width fs20'>
                      {t('level')} {item.currentLevel}
                    </p>
                  </div>
                  <div>
                    {item.nextLevelCosts ? (
                      <div>
                        <ul className='flex ul_construction_ressources' style={{ listStyle: 'none' }}>
                          <li className='mr20 fs22 flex'>
                            <GiAnt className='fs32 icon_color_dore fs_responsive_logo' />
                            <p className='fs22 ml8 lg_fs20'>{item.nextLevelCosts.workers}</p>
                          </li>
                          <li>
                            <FaAppleAlt className='fs32 icon_color_dore fs_responsive_logo' />
                            <p className='fs22 ml8 lg_fs20'>{item.nextLevelCosts.food}</p>
                          </li>
                          <li className='mr20 fs22 flex'>
                            <GiWaterDrop className='fs32 icon_color_dore fs_responsive_logo' />
                            <p className='fs22 ml8 lg_fs20'>{item.nextLevelCosts.water}</p>
                          </li>
                          <li className='mr20 fs22 flex'>
                            <GiWoodBeam className='fs32 icon_color_dore fs_responsive_logo' />
                            <p className='fs22 ml8 lg_fs20'>{item.nextLevelCosts.wood}</p>
                          </li>
                          <li className='mr20 fs22 flex'>
                            <GiHoneyJar className='fs32 icon_color_dore fs_responsive_logo' />
                            <p className='fs22 ml8 lg_fs20'>{item.nextLevelCosts.honey}</p>
                          </li>
                        </ul>
                        <li className='mr40 fs22 flex li_time'>
                          <p>{formatDuration(item.nextLevelCosts.time)}</p>
                          <FaHourglassHalf className='mr15 fs22' />
                        </li>
                      </div>
                    ) : (
                      <p>{t('no_information')}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className='ml100'>
                <p>{t('description')}: {item.description}</p>

                <button
                  onClick={() => handleUpgrade(item.research_id)}
                  disabled={hasOngoingResearch || item.currentLevel >= item.laboratoryLevel}
                  className='button_1'
                >
                  {t('upgrade')}
                </button>
              </div>
            </div>
            {hasOngoingResearch && item.buildEndTime && (
              <div
                style={{
                  padding: '10px',
                  backgroundColor: '#f8f9fa',
                  textAlign: 'center',
                }}
              >
                <p style={{ color: 'red' }}>{t('research_in_progress')}</p>
                <p>{t('remaining_time')}: {getRemainingTime(item.buildEndTime)}</p>
                {item.buildEndTime && (
                  <button onClick={() => handleCancelUpgrade(ongoingResearch.research_id)}
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      padding: '10px',
                      border: 'none',
                      borderRadius: '5px',
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    {t('cancel')}
                  </button>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
      {hasOngoingResearch && (
        <p>{t('ongoing_research_message')}</p>
      )}
      <ErrorModal show={showError} handleClose={handleErrorClose} errorMessage={errorMessage} />
    </div>
  );
};

export default Laboratoire;
