import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../features/authSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/logo_1.png';
import { useSelector } from 'react-redux';

const Login = () => {
  const { t } = useTranslation();

  const loginError = useSelector((state) => state.auth.error);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await dispatch(login({ username, password }));
      localStorage.setItem('isAuthenticated', 'true');
      navigate('/');
    } catch (error) {
      console.error('Erreur lors de la connexion :', error);
    }
  };

  return (
    <div className='auth-container'>
      <p className='game_version'>BETA</p>
      <img src={logo} className='logo_auth' alt='Logo' />
      <h2 className='auth-title'>{t('login')}</h2>
      <div>
        <input
          type="text"
          placeholder={t('username')}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className='auth-input'
        />
      </div>
      <div>
        <input
          type="password"
          placeholder={t('password')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className='auth-input'
        />
      </div>
      <p>{loginError} </p>
      <div className='buttons-login-register'>
        <button onClick={handleLogin} className='mr10 fs25 button_1'>
          {t('play')}
        </button>
        <button onClick={() => navigate('/register')} className=' fs25 button_1'>
          {t('register')}
        </button>
      </div>
    </div>
  );
};

export default Login;
