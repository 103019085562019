import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './features/authSlice';
import eggProductionReducer from './features/eggProductionSlice';
import thunk from 'redux-thunk';


const loadState = () => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('reduxState', serializedState);
  } catch {
  }
};

const preloadedState = loadState();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    eggProductions: eggProductionReducer,
  },
  preloadedState: preloadedState,
  middleware : (getDefaultMiddleware)=> 
    getDefaultMiddleware().concat(thunk)
});

store.subscribe(() => {
  saveState(store.getState());
});
