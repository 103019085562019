import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Messagerie from './Messagerie';
import warrior from '../../assets/angry-speakers.png' 
import { IoEarth } from 'react-icons/io5';
import iconApple from '../../assets/IconFood.png';
import iconHoney from '../../assets/IconHoney.png';
import iconWater from '../../assets/IconWater.png';

const URL = process.env.REACT_APP_SERVER_URL;

const MessagerieTDC = () => {
  const { t } = useTranslation();

  const [messagesBattlesTDC, setMessagesBattlesTDC] = useState([]);
  const [units, setUnits] = useState([]);

  const colony = useSelector((state) => state.auth.colony);
  
  const fetchMessagesBattlesTDC = async () => {
    const response = await axios.get(`${URL}/api/battlesTDC/${colony.id}`);
    setMessagesBattlesTDC(response.data);
    return response.data;
  };

  const MarkAsReaded = async () => {
    const response = await axios.post(`${URL}/api/message_read/${colony.id}`);
    return response.data;
  };

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${URL}/api/units`);
      setUnits(response.data);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  useEffect(() => {
    const handler = async () => {
      await fetchUnits();
      if (colony) {
        await fetchMessagesBattlesTDC();
        setTimeout( async()=> {
          await MarkAsReaded();
        }, 10000)
      }
    };
    handler();

  }, [colony]);

  return (
    <div className='messagerie-page'>
      {Messagerie()}
      {Array.isArray(messagesBattlesTDC) &&
        messagesBattlesTDC.map((message, index) =>
          message.defender_id === null ? (
            <div key={index} className='messagerie-renderer'>
              <h2 className='messagerie-h2'>
                {t('conqueteNouvellesTerres')}
                {''}{' '}
                {message.score_degat[message.score_degat.length - 1] ===
                true ? (
                  <div>
                  <p className='message-victory'>{t('victoire')}! +{message.surface_request} <IoEarth className='icon_ressource_modal icon_color_earth' />{' '}
 </p>
 <p>
  + {message.surface_request} <img src={iconApple} alt='food' className='icon_ressource_modal' ></img>{' '} + {Math.round(Number(message.surface_request)*0.8)} <img src={iconWater}  className='icon_ressource_modal'></img>{' '}   +  {Math.round(Number(message.surface_request)*0.4)}  <img src={iconHoney}   className='icon_ressource_modal'></img>{' '}
 </p>
 </div>
                ) : (
                  <div>
                  <p className='message-defeat'>{t('defaite')}</p>
                  <p>
                  + {message.surface_request} <img src={iconApple} alt='food' className='icon_ressource_modal' ></img>{' , '} + {Math.round(Number(message.surface_request)*0.8)} <img src={iconWater} alt='water' className='icon_ressource_modal' ></img>{' , '}   +  {Math.round(Number(message.surface_request)*0.4)}  <img src={iconHoney} alt='honey' className='icon_ressource_modal' ></img>
                 </p>
                 </div>
                )}{' '}
              </h2>
              <div>
                {message.read === 0 && message.score_degat[message.score_degat.length - 1] ===
                true ? (
                  <div className='message_battle_area'>
                    <div className='message_battle_attacker'>
                      <img src={warrior} className='logo_nav' alt='Logo' />
                      <div className='message_battle_life_thumb'>
                      <div className={`message_battle_life_winner `}></div>
                      </div>
                    </div>
                    <div className='message_battle_defender'>
                      <img src={warrior} className='logo_nav' alt='Logo' />
                      <div className='message_battle_life_thumb'>
                      <div className='message_battle_life_looser'></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  message.read === 0 && message.score_degat[message.score_degat.length - 1] ===
                    false ? (
                      <div className='message_battle_area'>
                        <div className='message_battle_attacker'>
                          <img src={warrior} className='logo_nav' alt='Logo' />
                          <div className='message_battle_life_thumb'>
                          <div className={`message_battle_life_looser`}></div>
                          </div>
                        </div>
                        <div className='message_battle_defender'>
                          <img src={warrior} className='logo_nav' alt='Logo' />
                          <div className='message_battle_life_thumb'>
                          <div className='message_battle_life_winner'></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )
                )}
              </div>
              <p>
                {t('escadronRencontre')} {message.insect_list.join(' ')}
              </p>
              <p>______________________</p>
              <p>{t('issueAffrontement')}</p>
              <p>
                {t('degatsInfliges')} {message.score_degat[0][0]}{' '}
                {t('degatsRecus')}
              </p>
              <p>{message.score_degat[0][1]} .</p>
              {message.score_degat.length > 3 ? (
                <div>
                  <p>
                    {t('degatsInfliges')} {message.score_degat[1][0]}{' '}
                    {t('degatsRecus')}
                  </p>
                  <p>{message.score_degat[1][1]}</p>
                </div>
              ) : null}
              <p>______________________</p>
              <p>{t('pertes')} </p>
              {message.unit_loose.map((unit, index) => (
                <p>
                  {unit.count}
                  {units.map((u) =>
                    u.id === unit.id ? (
                      <img
                        src={`${URL}${u.imageUrl}` || ''}
                        alt='unit'
                        className='image_around_tdc'
                      />
                    ) : (
                      ''
                    )
                  )}{' '}
                  {index !== 0 ? ', ' : ''}{' '}
                </p>
              ))}
            </div>
          ) : (
            <p>Need refreach</p>
          )
        )}
    </div>
  );
};

export default MessagerieTDC;
