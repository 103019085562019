import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { fetchConvois } from '../features/authSlice';

const URL = process.env.REACT_APP_SERVER_URL;

const Convois = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [convoisForFood, setConvoisForFood] = useState(0);
  const [convoisForWood, setConvoisForWood] = useState(0);
  const [convoisDestinataire, setConvoisDestinataire] = useState('');
  const [workers, setWorkers] = useState(0);
  const [remainingTime, setRemainingTime] = useState([]);
  const [messageError, setMessageError] = useState('');

  const accessToken = useSelector((state) => state.auth.accessToken);
  const colony = useSelector((state) => state.auth.colony);
  const convois = useSelector((state) => state.auth.convois);

  const formatTime = (milliseconds) => {
    if (milliseconds < 0) return 0;

    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${days}J ${hours}H ${minutes}m ${seconds}s`;
  };

  const calculateTimeRemaining = async () => {
    if (convois) {
      setRemainingTime([]);
      for (let convoi of convois) {
        let completed_at = new Date(convoi.completed_at);
        let timeNow = new Date();

        if (completed_at > timeNow) {
          let difference = completed_at.getTime() - timeNow.getTime();
          setRemainingTime((prev) => [...prev, difference]);
        } else {
          setRemainingTime((prev) => [...prev, 0]);
        }
      }
    }
  };

  const CreateConvois = async (
    colony_id,
    convoisForFood,
    convoisForWood,
    destinataire,
    newCount
  ) => {
    try {
      const response = await axios.post(
        `${URL}/api/convois`,
        { colony_id, convoisForFood, convoisForWood, destinataire, newCount },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setMessageError('Resources send');

      return response.data;
    } catch (err) {
      if (err.response.status === 403) {
        navigate('/login');
      } else if (err.response.status === 400) {
        setMessageError('User not found');
      } else if (err.response.status === 401) {
        setMessageError('0 ressources selected');
      } else if (err.response.status === 402) {
        setMessageError('Not enought workers');
      }
    }
  };

  const fetchWorkers = async () => {
    try {
      const response = await axios.get(
        `${URL}/api/units/workers/${colony.id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setWorkers(response.data);
    } catch (error) {
      console.error('Error fetching army units:', error);
    }
  };

  useEffect(() => {
    const handler = async () => {
      await fetchWorkers();
      dispatch(fetchConvois());
    };
    handler();
    calculateTimeRemaining();
    const interval = setInterval(() => {
      calculateTimeRemaining();
      dispatch(fetchConvois());
      fetchWorkers();
      setMessageError('');
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const handlerCreateConvois = async () => {
    let newCount = Math.round(
      Math.round(
        workers - (Number(convoisForFood) + Number(convoisForWood)) * 0.1
      )
    );

    await CreateConvois(
      colony.id,
      Number(convoisForFood),
      Number(convoisForWood),
      convoisDestinataire,
      newCount
    );
  };

  return (
    <div className='convois'>
      <div className='convois-handler'>
        <h1 className='convois-title'>Convois</h1>
        {convois.length > 0 && remainingTime.length > 0 ? (
          remainingTime.map((time, index) => (
            <div>Vos ouvrières rentrent dans {formatTime(time)}</div>
          ))
        ) : (
          <div>Donnez un coup de main à un ami !</div>
        )}
        <p>Username : </p>
        <div className='form-army'>
          <input
            type='text'
            placeholder='name'
            value={convoisDestinataire}
            onChange={(e) => setConvoisDestinataire(e.target.value)}
          />
        </div>
        <p>Food : </p>
        <div className='form-army'>
          <input
            type='number'
            placeholder='0'
            value={convoisForFood}
            onChange={(e) => {
              if (e.target.value < 0) {
                setConvoisForFood(0);
              } else if (e.target.value > colony.food_storage) {
                setConvoisForFood(colony.food_storage);
              } else {
                setConvoisForFood(e.target.value);
              }
            }}
          />
        </div>
        <p>Wood : </p>
        <div className='form-army'>
          <input
            type='number'
            placeholder='0'
            value={convoisForWood}
            onChange={(e) => {
              if (e.target.value < 0) {
                setConvoisForWood(0);
              } else if (e.target.value > colony.wood_storage) {
                setConvoisForWood(colony.wood_storage);
              } else {
                setConvoisForWood(e.target.value);
              }
            }}
          />
        </div>
        <p>
          nombre d'ouvrières requise :{' '}
          {Math.round((Number(convoisForFood) + Number(convoisForWood)) / 10)}
        </p>
        <p>{messageError}</p>
        <button className='button_1' onClick={handlerCreateConvois}>
          click
        </button>
        {/* <div><p>
          {convois}  
          </p>  
        </div> */}
      </div>
    </div>
  );
};

export default Convois;
