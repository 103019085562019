// src/components/ResourceModal.js
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { fetchColony } from '../features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FaAppleAlt } from 'react-icons/fa';
import { GiDrippingHoney, GiWoodStick } from 'react-icons/gi';
import { IoIosWater } from 'react-icons/io';
import { IoEarth } from 'react-icons/io5';
import workerImg from '../assets/unit_0.webp';
import { useNavigate } from 'react-router-dom';
const URL = process.env.REACT_APP_SERVER_URL;

const ResourceModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [workersCount, setWorkersCount] = useState(0);

  const colony = useSelector((state) => state.auth.colony);

  const fetchWorkers = async (colony_id) => {
    try {
      const response = await axios.get(`${URL}/api/units/workers/${colony_id}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  const getWorkersCount = async () => {
    if (colony) {
      const count = await fetchWorkers(colony.id);
      if (count > 0) {
        setWorkersCount(count);
        return count;
      }
    }
  };

  useEffect(() => {
    const handler = async () => {
      await getWorkersCount();
    };
    dispatch(fetchColony(navigate));
    handler();
    const interval = setInterval(async () => {
      dispatch(fetchColony(navigate));
      await getWorkersCount();
    }, 3000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className='modale'
      onClick={handleClose}
    >
      <Modal.Body>
        {colony ? (
          <div>
            <div className='h2 ressourceModal_header'>      
                {colony ? colony.name : null}{' '}
                <div className='component_territory'>
                <IoEarth className='icon_ressource_modal icon_color_earth' />{' '}
                {colony ? colony.terrain_chasse : null} 
                </div>
            </div>
            <p className='ml100'>
              <img src={workerImg} className='logo_nav' alt='Logo' />
              {workersCount}{' '}
            </p>
            <div className='flex_row'>
            <p>
              <FaAppleAlt className='icon_ressource_modal icon_color_apple' />{' '}
              {colony ? colony.food_storage : null}
            </p>
            <p>
              <IoIosWater className='icon_ressource_modal icon_color_water' />{' '}
              {colony ? colony.water_storage : null}
            </p>
            <p>
              <GiWoodStick className='icon_ressource_modal icon_color_wood' />{' '}
              {colony ? colony.wood_storage : null}
            </p>
            <p>
              <GiDrippingHoney className='icon_ressource_modal icon_color_honey' />{' '}
              {colony ? colony.honey_storage : null}
            </p>
            </div>
          </div>
        ) : (
          <p>Loading colony information...</p>
        )}
      </Modal.Body>
    
    </Modal>
  );
};

export default ResourceModal;
