import { useTranslation } from 'react-i18next';

const Tuto = () => {
  const { t } = useTranslation();
  
  return (
    <div className="tuto">
      <h1 className="tuto-title">{t('title')}</h1>
      <div className='tuto-renderer '>
        <p className="fs25 pt40">
          {t('discordInfo')}
        </p>{' '}
        <a className="tuto-a fs25" href={t('discordLink')}>https://discord.gg/Z67ndepm</a>
        <p className="fs25 pt40">
          {t('welcomeMessage')}
        </p> 
        <p className="fs25 pt40">
          {t('queenInfo')}
        </p>
        <p className="fs25 pt40">
          {t('resourceManagerInfo')}
        </p>
        <p className="fs25 pt40">
          {t('enemyPageInfo')}
        </p>
        <p className="fs25 pt40">
          {t('coloniesPageInfo')}
        </p>
        <p className="fs25 pt40">
          {t('constructionLabInfo')}
        </p>
        <p className="fs25 pt40">
          {t('discordInfo')}
        </p>{' '}
        <a className="fs25 tuto-a" href={t('discordLink')}>https://discord.gg/Z67ndepm</a>
      </div>
    </div>
  );
};

export default Tuto;
