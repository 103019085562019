import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ColoniesInfo from '../components/InformationModal.js/ColoniesInfo';

const URL = process.env.REACT_APP_SERVER_URL;

const ColonyAndArmies = () => {
  const { t } = useTranslation();

  const [colonies, setColonies] = useState([]);
  const [showInfo, setShowInfo] = useState(false);

  const handleInfoClose = () => setShowInfo(false);
  const handleInfoShow = () => setShowInfo(true);

  const accessToken = useSelector((state) => state.auth.accessToken);

  const fetchColonizedColonies = async () => {
    try {
      const response = await axios.get(`${URL}/api/colonized`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log('Colonies received:', response.data);
      setColonies(response.data);
    } catch (error) {
      console.error('Error fetching colonized colonies:', error);
    }
  };

  useEffect(() => {
    fetchColonizedColonies();
    const intervalId = setInterval(async () => {
      fetchColonizedColonies();
    }, 2000);

    return async () => clearInterval(intervalId);
  }, [accessToken]);

  return (
    <div className='colonies'>
      <h2 className='colonies-title'>{t('colonized_colonies')} <button onClick={handleInfoShow} className='button_info'>
              i
           </button></h2>
      
           <ColoniesInfo
            show={showInfo}
            handleClose={handleInfoClose}
          />  
      <div>
        {colonies.map((colony) => (
          <div key={colony.id} className='colonies-renderer'>
            <p className='colonies-colony'>{colony.name}</p>
            <p>
              {colony.stationedArmies &&
                colony.stationedArmies.map((unit) => (
                  <div key={unit.id}>
                    {unit.count} x {unit.Unit.name}
                  </div>
                ))}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
  
};

export default ColonyAndArmies;
