// src/pages/Map.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css'; // Assurez-vous de créer et de styliser ce fichier CSS
import { useTranslation } from 'react-i18next';

const URL = process.env.REACT_APP_SERVER_URL;

const Map = () => {
  const { t } = useTranslation();

  const [colonies, setColonies] = useState([]);
  const [viewPort, setViewPort] = useState({
    x: 0,
    y: 0,
    width: 10,
    height: 10,
  });

  const fetchColonies = async () => {
    try {
      const response = await axios.get(`${URL}/api/map/colonies`);
      setColonies(response.data);
    } catch (error) {
      console.error('Error fetching colonies:', error);
    }
  };

  useEffect(() => {
    fetchColonies();
    const interval = setInterval(() => {
      fetchColonies();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const moveViewport = (direction) => {
    setViewPort((prev) => {
      let newX = prev.x;
      let newY = prev.y;
      if (direction === 'left') newX = Math.max(prev.x - 10, 0);
      if (direction === 'right') newX = Math.min(prev.x + 10, 9990);
      if (direction === 'up') newY = Math.max(prev.y - 10, 0);
      if (direction === 'down') newY = Math.min(prev.y + 10, 9990);
      return { ...prev, x: newX, y: newY };
    });
  };

  const visibleColonies = colonies.filter(
    (colony) =>
      colony.x_position >= viewPort.x &&
      colony.x_position < viewPort.x + viewPort.width &&
      colony.y_position >= viewPort.y &&
      colony.y_position < viewPort.y + viewPort.height
  );

  return (
    <div>
      <h2>{t('map_title')}</h2>
      <button onClick={() => moveViewport('up')}>{t('move_up')}</button>
      <button onClick={() => moveViewport('down')}>{t('move_down')}</button>
      <button onClick={() => moveViewport('left')}>{t('move_left')}</button>
      <button onClick={() => moveViewport('right')}>{t('move_right')}</button>
      <div className='map-grid'>
        {Array.from({ length: viewPort.height }).map((_, row) => (
          <div key={row} className='map-row'>
            {Array.from({ length: viewPort.width }).map((_, col) => {
              const x = viewPort.x + col;
              const y = viewPort.y + row;
              const colony = visibleColonies.find(
                (c) => c.x_position === x && c.y_position === y
              );
              return (
                <div key={col} className='map-cell'>
                  {colony ? colony.name : ''}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Map;
