import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const Guilds = () => {
    const { t } = useTranslation();

    const [guilds, setGuilds] = useState([]);
    const [guildName, setGuildName] = useState('');
    const accessToken = useSelector((state) => state.auth.accessToken);
    const userId = useSelector((state) => state.auth.user?.id); // Récupérer userId

    useEffect(() => {
        fetchGuilds();
    }, []);

    const fetchGuilds = async () => {
        try {
            const response = await axios.get('http://localhost:5000/api/guilds', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setGuilds(response.data);
        } catch (error) {
            console.error('Error fetching guilds:', error);
        }
    };

    const handleCreateGuild = async () => {
        try {
            const response = await axios.post('http://localhost:5000/api/guilds/create', { name: guildName, user_id: userId }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log('Guild created:', response.data);
            fetchGuilds();
        } catch (error) {
            console.error('Error creating guild:', error);
        }
    };

    const handleJoinGuild = async (guildId) => {
        try {
            const response = await axios.post('http://localhost:5000/api/guilds/join', { guild_id: guildId, user_id: userId }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log('Joined guild:', response.data);
            fetchGuilds();
        } catch (error) {
            console.error('Error joining guild:', error);
        }
    };

    return (
        <div>
          <h2>{t('guilds_title')}</h2>
          <input
            type="text"
            placeholder={t('guild_name_placeholder')}
            value={guildName}
            onChange={(e) => setGuildName(e.target.value)}
          />
          <button onClick={handleCreateGuild}>{t('create_guild')}</button>
          <ul>
            {guilds.map(guild => (
              <li key={guild.id}>
                {guild.name} ({t('leader')}: {guild.leader_id})
                <button onClick={() => handleJoinGuild(guild.id)}>{t('join_guild')}</button>
              </li>
            ))}
          </ul>
        </div>
      );      
};

export default Guilds;
